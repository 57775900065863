@use '@angular/material' as mat;
@import 'variables';
@include mat.core();

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-primary-palette,
      accent: $light-accent-palette
    )
  )
);

$dark-primary: mat.m2-define-palette(mat.$m2-blue-grey-palette);
$dark-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $dark-primary-palette,
      accent: $dark-accent-palette
    )
  )
);

@include mat.all-component-themes($light-theme);

* {
  letter-spacing: normal;
  font-family: 'ABCFavoritPro', sans-serif;
  font-size: 16px;
}

:root {
  //font-size: 62.5%;
  --red: #{$red};
  --green: #{$green};
  --green-light: #{$green-light};
  --grey-700: #{$grey-700};
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  --mat-menu-item-label-text-size: 0.875rem;
}

.dark-theme {
  filter: invert(1);
  //@include mat.all-component-colors($dark-theme);
  .fi,
  img.image-search-img,
  img.company-logo {
    filter: invert(1) !important;
  }
}

.mat-typography {
  font-family: 'ABCFavoritPro', sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'ABCFavoritPro', sans-serif;
  }
}

mat-icon {
  font-family: 'Material Symbols Rounded' !important;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.pointer,
a,
i {
  cursor: pointer;
}

.button-row {
  display: flex;
  gap: 0;
  flex-wrap: wrap;
  margin-top: 1rem;
  @include for-tablet-portrait-up {
    margin-top: 0;
    gap: $spacer;
  }
}

.page-inner-container {
  padding: 0 1rem;
  @include for-tablet-landscape-up {
    margin: 0 4rem;
  }
  @include for-desktop-up {
    margin: 0 8rem;
  }
}

.settings-heading {
  text-transform: uppercase;
  font-size: 0.75rem !important;
  line-height: 200% !important;
  color: var(--grey-700);
  margin: 0 !important;
  padding: $spacer-2 0;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: $grey-100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-500;
    border-radius: 5px;
  }
}

.w-100 {
  width: 100%;
}

.mat-drawer-container {
  background-color: $white !important;
}

.mat-mdc-snack-bar-container {
  &.mat-snackbar-warn,
  &.mat-snackbar-success {
    --mdc-snackbar-supporting-text-color: white;
    --mat-snack-bar-button-color: white;
  }

  &.mat-snackbar-warn {
    --mdc-snackbar-container-color: var(--red);
  }

  &.mat-snackbar-success {
    --mdc-snackbar-container-color: var(--green);
  }
}

.mat-mdc-button-base {
  &.mat-warn {
    --mdc-filled-button-container-color: var(--red);
  }
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
.mat-mdc-option .mdc-list-item__primary-text {
  font-family: 'ABCFavoritPro', sans-serif !important;
}

.mdc-form-field > label {
  cursor: pointer;
}

.mat-mdc-button,
.mat-mdc-button-base {
  .mdc-button__label {
    font-size: 0.875rem;
  }
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  --mdc-tab-indicator-active-indicator-color: var(--green-light);
  --mat-tab-header-active-focus-indicator-color: var(--green-light);
  --mat-tab-header-active-hover-indicator-color: var(--green-light);
}

.mat-mdc-select-min-line,
.mat-mdc-option .mdc-list-item__primary-text,
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.mat-step-label.mat-step-label-selected,
.mat-step-text-label,
.mat-step-icon-content span,
.mat-step-icon .mat-icon {
  font-size: 0.875rem !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-font: 'ABCFavoritPro', sans-serif;
  --mdc-chip-label-text-size: 0.875rem;
}

.mat-step-icon .mat-icon {
  display: flex;
  justify-content: center;
}
