@use '@angular/material' as mat;

// https://material.angular.io/guide/theming#palettes
$light-primary-palette: mat.m2-define-palette(mat.$m2-grey-palette, 900);
$light-accent-palette: mat.m2-define-palette(mat.$m2-green-palette, 600);

$dark-primary-palette: mat.m2-define-palette(mat.$m2-indigo-palette);
$dark-accent-palette: mat.m2-define-palette(mat.$m2-blue-grey-palette);

$primary-color: mat.m2-get-color-from-palette($light-primary-palette);
$accent-color: mat.m2-get-color-from-palette($light-accent-palette);

/* COLORS: */
$white: #ffffff;
$black: #000000;

$grey-100: #f5f5f5;
$grey-150: #eff3f6;
$grey-200: #e6e6e6;
$grey-225: #e6ebf0;
$grey-250: #d2d1cd;
$grey-300: #cccccc;
$grey-350: #e3e1de;
$grey-375: #bab9bb;
$grey-400: #68666d;
$grey-425: #828087;
$grey-450: #5c5a62;
$grey-500: #555555;
$grey-550: #46444a;
$grey-600: #333333;
$grey-700: #2d2c30;
$grey-750: #121212;

$blue-100: #add8e6;
$blue-200: #0fb0eb;
$blue-300: #1e90ff;
$blue-400: #007bff;

$green: #39823c;
$green-light: #69d94c;
$red: #d33c33;
$orange: orange;
$purple: purple;

/* SPACERS: */
$spacer-1: 0.25rem;
$spacer-2: 0.5rem;
$spacer: 1rem;
$spacer-4: 1.5rem;
$spacer-5: 2rem;
$spacer-6: 3rem;

/* TYPOGRAPHY: FONT SIZES */
$font-size-md: 0.875rem; // 14px
$font-size-reg: 1rem; // 16px
$font-size-lg: 1.125rem; // 18px
$font-size-xlg: 1.5rem; //24px

/* TYPOGRAPHY: FONT WEIGHTS */
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-700: 700;

/* MEDIA QUERIES: */
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
