@font-face {
  font-family: 'ABCFavoritPro';
  src: url('./ABCFavoritPro-Regular.woff') format('woff2'),
    url('./ABCFavoritPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ABCFavoritPro';
  src: url('./ABCFavoritPro-RegularItalic.woff2') format('woff2'),
    url('./ABCFavoritPro-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ABCFavoritPro';
  src: url('./ABCFavoritPro-Light.woff2') format('woff2'),
    url('./ABCFavoritPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ABCFavoritPro';
  src: url('./ABCFavoritPro-LightItalic.woff2') format('woff2'),
    url('./ABCFavoritPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
